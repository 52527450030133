.view {
  display: table;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  padding: 2rem 0;
}

.app-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.header {
  border-bottom: solid 1px var(--border-color);
  gap: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 1rem;
}

.header > h6 {
  margin-bottom: unset;
}

.loading {
  border: 3px solid var(--upper-gradient);
  border-radius: 50%;
  border-top-color: var(--lower-gradient);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin: auto;
  margin-top: 10rem;
}

.no-files {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-files-text {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.table-container {
  padding: 2rem 0;
  width: 100%;
}

th {
  background-color: #f6f8fa !important;
  width: 100%;
}

td {
  width: 100%;
}

td,
.summary-row > p,
.summary-row > div > p {
  font-family: "Lexend";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #33465b !important;
}

td > a {
  color: #3f8fab !important;
  text-decoration: none;
}

.header-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  font-family: "Lexend";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: max-content;
  color: #374659 !important;
  padding: 2px;
}

.sorting-arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  cursor: pointer;
}

.selected-arrow path {
  fill: #3f8fab;
}

.summary-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  gap: 1rem;
}

.summary-row > div {
  margin: 0 0.25rem;
  display: flex;
}

.summary-row > div > p,
.summary-row > p {
  margin: 0;
}

.summary-row .deal-name {
  padding-left: 12px;
  font-weight: 300;
  font-size: 12px;
  text-overflow: ellipsis;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
}

.table {
  margin-bottom: 0;
}

.first-table .table-responsive {
  overflow: scroll;
  max-height: 325px;
}

.second-table .table-responsive {
  overflow: scroll;
  max-height: 165px;
}

.table-responsive table thead {
  width: 100%;
  position: sticky;
  top: 0;
  margin: 0;
  outline: solid 1.5px #dfe3e7;
  outline-offset: -1px;
  z-index: 1;
}
.table-responsive table tfoot {
  width: 100%;
  position: sticky;
  margin: 0;
  top: 0;
  outline: solid 1.5px #dfe3e7;
  outline-offset: -1px;
  z-index: 1;
}

.total-revenue-text > p {
  font-family: "Lexend";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
